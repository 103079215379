import Link from "next/link";
import Image from "next/image"

export default function Custom404() {
    return (
        <div className="bg-gray-900 w-full h-screen block pt-36 px-2 md:px-0">
            <div className="w-full  md:w-[650px] border mx-auto h-[350px] rounded-lg bg-white bg-[url('/404.svg')] bg-cover bg-no-repeat bg-center">
                <div className="grid grid-cols-5 ">
                    <div className="col-span-2 md:col-span-3 text-center">
                        <Image
                            className="rounded-lg float-left h-20 mt-[-10px]"
                            src="/eticex.svg"
                            width={123}
                            height={40}
                            unoptimized={true}
                            priority={true} 
                            alt={"Eticex"} />
                    </div>
                    <div className="col-span-3 md:col-span-2 px-2">
                        <h1 className="text-orange-500 font-semibold text-4xl mt-20 ">404</h1>
                        <h2 className="text-orange-500 font-normal text-lg">Sayfa bulunamadı!</h2>
                        <p className="block mb-4 text-base">
                        Erişmeye çalıştığınız sayfa bulunamadı, geçici olarak askıya alınmış olabilir.
                        </p>
                        <Link href="/" className="bg-gray-900 text-white  py-2 text-center rounded-md w-full block text-sm">
                            Anasayfa
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}